/// <reference path="o365.pwa.declaration.sw.routehandlers.initializeDefaultRouteHandler.d.ts" />

import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

(() => {
    const { DynamicWorkboxStrategy } = self.o365.importScripts<typeof import('o365.pwa.declaration.sw.strategies.DynamicWorkboxStrategy.d.ts')>('o365.pwa.modules.sw.strategies.DynamicWorkboxStrategy.ts');
    const { contextRouteHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.sw.routehandlers.api.context.d.ts')>('o365.pwa.modules.sw.routehandlers.api.context.ts');

    function initializeDefaultRouteHandler() {
        self.o365.registerRoute(
            new RegExp('\/browserLinkSignalR\/*'),
            new self.workbox.strategies.NetworkOnly(),
            'POST'
        );

        self.o365.registerDefaultRoute(new DynamicWorkboxStrategy({
            'cacheName': 'default_cache',
            'networkTimeoutSeconds': 30
        }));
        
        contextRouteHandler();
    }

    self.o365.exportScripts<typeof import('o365.pwa.declaration.sw.routehandlers.initializeDefaultRouteHandler.d.ts')>({ initializeDefaultRouteHandler });
})();
